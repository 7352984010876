import React from "react"
import Header from "../components/Header"
import Title from "../components/Title"
import Image from "../components/Image"

export default function ContactUs() {
  const ContactUsForm = () => {
    return (
      <>
        <form
          className="ezds-contact-us-container-form"
          action="https://getform.io/f/14f054f7-0a35-4c73-a10d-53df83973e8b"
          method="POST"
        >
          <div className="ezds-contact-us-form-container">
            <div className="ezds-contact-us-form-container-left">
              <input
                type="text"
                className="ezds-contact-us-form-container-input"
                name="name"
                placeholder="Name *"
                required="true"
              ></input>
              <input
                type="text"
                className="ezds-contact-us-form-container-input"
                name="email"
                placeholder="Email *"
              ></input>
              <input
                type="text"
                className="ezds-contact-us-form-container-input"
                name="subject"
                placeholder="Subject"
              ></input>
              <input
                type="text"
                className="ezds-contact-us-form-container-input"
                name="mobile"
                placeholder="Mobile *"
                required="true"
              ></input>
            </div>
            <div className="ezds-contact-us-form-container-right">
              <textarea
                className="ezds-contact-us-form-container-textarea"
                name="message"
                placeholder="Message"
              ></textarea>
            </div>
          </div>
          <div className="ezds-contact-us-form-container-bottom">
            <button
              type="submit"
              className="ezds-contact-us-form-container-button"
            >
              Send
            </button>
          </div>
        </form>
      </>
    )
  }

  return (
    <>
      <Header />
      <Title />
      <div className="ezds-contact-us-container">
        <div className="ezds-contact-us-widget">
          <span>Contact us</span>
        </div>
        <div className="ezds-contact-us-description">
          <p>
            <br />
            {`
            ​ Write to us at terpsichore@outlook.com or simply fill this form
            (booking and/or questions) and we’ll get back to you shortly:
            `}
          </p>
          <br />
          <p>
            For {<b className="ezds-contact-us-description--bold">bookings:</b>}{" "}
            Please indicate the date of arrival and of departure. We will keep
            in contact to confirm the booking.
          </p>
        </div>
        {ContactUsForm()}
        <div className="ezds-contact-us-footer-container">
          <div className="ezds-contact-us-footer-row-text">
            <span>{'"Feel at home"'}</span>
            <span>
              {
                "Villa Fedrita is owned & maintained by Lucas & Karina and their two daughters Fedra and Evita"
              }
            </span>
            <span>{"Lucas & Karina"}</span>
          </div>
          <div className="ezds-contact-us-footer-row-image">
            <Image
              fileName="fedrita-contact.jpg"
              style={{ width: "550px", height: "400px" }}
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  )
}
